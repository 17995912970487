import React, { useState } from 'react';
import { navigate, Link } from 'gatsby';
import { useLocation } from '@reach/router';

import { useAuth, providerGoogle } from '../app';
import { SignInForm } from './Authorize/SignInForm';
import { SignUpForm } from './Authorize/SignUpForm';
import { ResetPasswordForm } from './Authorize/ResetPasswordForm';
import { routes } from '../utils/constants';

import css from './Authorize.module.css';
import google from '../images/g-logo.png';

export const Authorize = () => {
  const { state, isLoggedIn, login } = useAuth();
  const location = useLocation();
  const [type, setType] = useState('login'); // login | register | reset

  if (isLoggedIn) {
    if (location && location.state && location.state.redirect) {
      navigate(location.state.redirect, { replace: true });
      return null;
    }

    navigate(routes.profile, { replace: true });
    return null;
  }

  return (
    <div className={css.container}>
      {state === 'error' && <p>Nepodarilo sa prihlásiť{/*, <Link to={routes.project}>kontaktujte nás</Link>*/}</p>}

      {type === 'login' && (
        <>
          <h3>Prihlásenie</h3>
          <div className={css.social}>
            <button
              onClick={() => login(providerGoogle(), location.state ? location.state.redirect : undefined)}
              disabled={state === 'pending'}
              className={css.buttonGoogle}
            >
              <img src={google} alt="Google logo" />
              Prihlásiť sa pomocou Google
            </button>
          </div>

          <p style={{ padding: '0.5rem' }}>
            <small>alebo</small>
          </p>

          <SignInForm />

          <p>
            <small>
              <a
                href="#0"
                onClick={(e) => {
                  e.preventDefault();
                  setType('reset');
                }}
              >
                Zabudli ste heslo?
              </a>
            </small>
          </p>

          <p>
            <a
              href="#0"
              onClick={(e) => {
                e.preventDefault();
                setType('registration');
              }}
            >
              Vytvoriť účet
            </a>
          </p>
        </>
      )}

      {type === 'registration' && (
        <>
          <h3>Registrácia</h3>
          <SignUpForm />

          <p>
            <a
              href="#0"
              onClick={(e) => {
                e.preventDefault();
                setType('login');
              }}
            >
              Máte už účet? Prihláste sa!
            </a>
          </p>
        </>
      )}

      {type === 'reset' && (
        <>
          <h3>Resetovať heslo</h3>
          <ResetPasswordForm />

          <p>
            <a
              href="#0"
              onClick={(e) => {
                e.preventDefault();
                setType('login');
              }}
            >
              Späť na prihlásenie
            </a>
          </p>
        </>
      )}

      <p>
        <small>
          Prihlásením sa potvrdzujete, že ste si prečítali <Link to={routes.terms}>Podmienky používania</Link> a{' '}
          <Link to={routes.gdpr}>Zásady ochrany osobných údajov</Link> a súhlasíte s nimi.
        </small>
      </p>
    </div>
  );
};
