import React from 'react';

import { Meta } from '../components/Meta';
import { Main } from '../components/Main';
import { Authorize } from '../components/Authorize';

const title = 'Prihlásenie';

export default () => {
  return (
    <Main>
      <Meta title={title} />
      <h1>{title}</h1>
      <p>Po prihlásení môžete pridať inzerát na vami ponúkaný tovar alebo službu.</p>
      <Authorize />
    </Main>
  );
};
