import React from 'react';
import firebase from 'gatsby-plugin-firebase';
import { Form, Field } from 'react-final-form';
import { toast } from 'react-toastify';

import { printError } from '../../utils';
import { required } from '../../utils/form';

export const SignInForm = () => {
  const onSubmit = async ({ email, password }) => {
    // TODO: Runs spinner
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);
    } catch (e) {
      printError(e);
      toast('Prihlásenie sa nepodarilo', { type: toast.TYPE.ERROR });
    } finally {
      // TODO: remove spinner
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, invalid, submitting }) => (
        <form onSubmit={handleSubmit} className="form">
          <label htmlFor="email">Email</label>
          <Field
            id="email"
            name="email"
            component="input"
            type="email"
            placeholder="meno@domena.sk"
            validate={required}
            disabled={submitting}
          />

          <label htmlFor="password">Heslo</label>
          <Field
            id="password"
            name="password"
            component="input"
            type="password"
            validate={required}
            disabled={submitting}
          />

          <button type="submit" disabled={invalid || submitting}>
            Prihlásiť sa
          </button>
        </form>
      )}
    />
  );
};
