import React from 'react';
import firebase from 'gatsby-plugin-firebase';
import { Form, Field } from 'react-final-form';
import { toast } from 'react-toastify';

import { useAuth } from '../../app';
import { printError } from '../../utils';
import { required, composeValidators, minChars, equalTo } from '../../utils/form';

export const SignUpForm = () => {
  const { sendEmailVerification } = useAuth();

  const onSubmit = async ({ name, email, password }) => {
    // TODO: Runs spinner
    try {
      const { user } = await firebase.auth().createUserWithEmailAndPassword(email, password);
      if (user) {
        await user.updateProfile({ displayName: name });
        await sendEmailVerification(user);
      }
      toast('Registrácia bola úspešná, prosím potvrďte odkaz, ktorý vám prišiel na email', {
        type: toast.TYPE.SUCCESS,
      });
    } catch (e) {
      printError(e);
      toast('Registrácia sa nepodarila', { type: toast.TYPE.ERROR });
    } finally {
      // TODO: remove spinner
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, invalid, submitting, values, touched, errors }) => (
        <form onSubmit={handleSubmit} className="form">
          <label htmlFor="name">Meno</label>
          <Field
            id="name"
            name="name"
            component="input"
            placeholder="Ján Novák"
            validate={required}
            disabled={submitting}
            className={errors['name'] && touched['name'] ? 'error' : ''}
          />

          <label htmlFor="email">Email</label>
          <Field
            id="email"
            name="email"
            component="input"
            type="email"
            placeholder="meno@domena.sk"
            validate={required}
            disabled={submitting}
            className={errors['email'] && touched['email'] ? 'error' : ''}
          />

          <label htmlFor="password">Heslo (minimálne 8 znakov)</label>
          <Field
            id="password"
            name="password"
            component="input"
            type="password"
            validate={composeValidators(required, minChars(8))}
            disabled={submitting}
            className={errors['password'] && touched['password'] ? 'error' : ''}
          />

          <label htmlFor="passwordtest">Zopakujte heslo pre kontrolu</label>
          <Field
            id="passwordtest"
            name="passwordtest"
            component="input"
            type="password"
            validate={composeValidators(required, equalTo(values.password, 'Heslá sa musia zhodovať'))}
            disabled={submitting}
            className={errors['passwordtest'] && touched['passwordtest'] ? 'error' : ''}
            key={`te${values.password}st`}
          />

          <button type="submit" disabled={invalid || submitting}>
            Vytvoriť účet
          </button>
        </form>
      )}
    />
  );
};
