import React from 'react';
import { Form, Field } from 'react-final-form';
import { toast } from 'react-toastify';

import { useAuth } from '../../app';
import { printError } from '../../utils';
import { required } from '../../utils/form';

export const ResetPasswordForm = () => {
  const { sendPasswordResetEmail } = useAuth();

  const onSubmit = async ({ email }) => {
    // TODO: Runs spinner
    try {
      await sendPasswordResetEmail(email);
      toast('Email pre zmenu hesla bol odoslaný', { type: toast.TYPE.INFO });
    } catch (e) {
      printError(e);
      toast('Email pre zmenu hesla sa nepodarilo odoslať', { type: toast.TYPE.ERROR });
    } finally {
      // TODO: remove spinner
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, invalid, submitting }) => (
        <form onSubmit={handleSubmit} className="form">
          <label htmlFor="email">Email</label>
          <Field
            id="email"
            name="email"
            component="input"
            type="email"
            placeholder="meno@domena.sk"
            validate={required}
            disabled={submitting}
          />

          <button type="submit" disabled={invalid || submitting}>
            Resetovať
          </button>
        </form>
      )}
    />
  );
};
